#cta-text h1 {
  max-width: 30.125rem;
  padding-bottom: 1rem;
  font-size: 48px;
  font-weight: 900;
  line-height: 64px;
}

#cta-text h2 {
  letter-spacing: 0;
  padding-bottom: 2rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

@media (width >= 768px) {
  #cta-text {
    padding: 4.875rem 2rem 7.5rem;
  }

  #cta-text h1 {
    padding-bottom: 3rem;
  }

  #cta-text h2 {
    padding-bottom: 6rem;
  }
}

#cta-text p {
  max-width: 30.125rem;
}

#down-arrow {
  background-image: url("arrow-down-icon.4ca63679.svg");
  width: 21px;
  height: 26px;
  margin-top: 1rem;
  margin-bottom: 20px;
  animation: 2.5s ease-in-out infinite bounce;
}

#cta-hero-image {
  background-image: url("friends-cooking.72461d95.jpg");
}

#cta-hero-image, #user-hero-image, #business-hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#stats-container {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

#stats h1, #stats h2 {
  letter-spacing: 0;
  margin-bottom: 1.5rem;
  font-weight: 900;
  line-height: 48px;
}

#stats h1 {
  font-size: 36px;
}

#stats h2 {
  font-size: 30px;
}

#stats .stat {
  max-width: 300px;
}

#stats-headline {
  margin-bottom: 4rem;
}

#stats img {
  width: 8.5rem;
  height: 8.5rem;
  margin-bottom: -2rem;
}

#user {
  color: #341f97;
  background-color: #f8f9fa;
}

#user-container, #business-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (width >= 768px) {
  #user-container, #business-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

#user-hero-image {
  background-image: url("happy-shopper.805d140f.jpg");
  background-position: center;
  background-size: cover;
}

#user h1, #business h1 {
  letter-spacing: 0;
  font-size: 48px;
  font-weight: 900;
  line-height: 64px;
}

#user p, #business p {
  padding-top: 2rem;
}

#user ul, #business ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#user ul li, #business ul li {
  align-items: center;
  padding-top: 2rem;
}

#user ul li img, #business ul li img {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

#user ul li span, .business-ul-text {
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
}

#business-container, #user-container {
  max-width: 992px;
}

#business-hero-image {
  background-image: url("food-pickup.a06dbcc1.jpg");
  background-position: center;
  background-size: cover;
}

.business-ul-img {
  width: 74px;
}

.business-ul-text {
  flex: 1;
}
/*# sourceMappingURL=index.0ad1e7dd.css.map */
