@import "/src/assets/scss/variables.scss";

#cta-text h1 {
    padding-bottom: 1rem;
    font-size: 48px;
    line-height: 64px;
    font-weight: 900;
    max-width: 30.125rem;
}

#cta-text h2 {
    padding-bottom: 2rem;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

@media (min-width: $md) {
    #cta-text {
        padding: 4.875rem 2rem 7.5rem;
    }

    #cta-text h1 {
        padding-bottom: 3rem;
    }
    
    #cta-text h2 {
        padding-bottom: 6rem;
    }
}

#cta-text p {
    max-width: 30.125rem;
}

#down-arrow {
    background-image: url('/src/assets/icons/arrow-down-icon.svg');
    width: 21px;
    height: 26px;
    animation: bounce 2.5s ease-in-out infinite;
    margin-top: 1rem;
    margin-bottom: 20px;
}

#cta-hero-image {
    background-image: url('/src/assets/images/friends-cooking.jpg');
}

#cta-hero-image, #user-hero-image, #business-hero-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#stats-container {
    padding-top: 6rem;
    padding-bottom: 4rem;
}

#stats h1, #stats h2 {
    margin-bottom: 1.5rem;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 48px;
}

#stats h1 {
    font-size: 36px;
}

#stats h2 {
    font-size: 30px;
}

#stats .stat {
    max-width: 300px;
}

#stats-headline {
    margin-bottom: 4rem;
}

#stats img {
    margin-bottom: -2rem;
    height: 8.5rem;
    width: 8.5rem;
}

#user {
    color: $primary;
    background-color: $light;
}

#user-container, #business-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media (min-width: $md) {
    #user-container, #business-container {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

#user-hero-image {
    background-image: url('/src/assets/images/happy-shopper.jpg');
    background-size: cover;
    background-position: center;
}

#user h1, #business h1 {
    font-size: 48px;
    line-height: 64px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 64px;
}

#user p, #business p {
    padding-top: 2rem;
}

#user ul, #business ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#user ul li, #business ul li {
    padding-top: 2rem;
    align-items: center;
}

#user ul li img, #business ul li img {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
}

#user ul li span, .business-ul-text {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: uppercase;
}

#business-container, #user-container {
    max-width: $lg;
}

#business-hero-image {
    background-image: url('/src/assets/images/food-pickup.jpg');
    background-size: cover;
    background-position: center;
}

.business-ul-img {
    width: 74px;
}

.business-ul-text {
    flex: 1;
}